
































































































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, inject, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import controldetailsDropdownData from '@/assets/data/controls-data.json'
import { TranslateResult } from 'vue-i18n'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import { NormDTO } from '@/dto/backend-response/normsDTO'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        editable: {
            type: Boolean,
        },
        superAdminEdit: {
            type: Boolean,
        },
        createdByUser: {
            type: Boolean,
        },
        normsCreatedByUser: {
            type: Array as () => Array<NormDTO>,
        },
    },
    setup(props) {
        const { t, tc } = useI18n()
        const { language } = useState(['language'])
        const { users } = useState(['users'])
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const formData = ref({
            NORM: 0,
            CATEGORY: '',
            SUB_CATEGORY: '',
            DESCRIPTION: '',
            OWNER: userId ? userId : 0,
            TOPIC: '',
            OBJECTUVE: '',
            CATEGORY_TEXT: '',
            SUB_CATEGORY_TEXT: '',
            MSR_NUMBER: '',
        })
        const errors = ref({
            OWNER: { expire: false, missingValue: false },
            NORM: { missingValue: false },
            MSR_NUMBER: { missingValue: false },
            TOPIC: { missingValue: false },
            DESCRIPTION: { missingValue: false },
            OBJECTUVE: { missingValue: false },
            CATEGORY_TEXT: { alreadyExist: false },
            SUB_CATEGORY_TEXT: { alreadyExist: false },
        })

        const labelInfomationOpNorms = ref(null)
        const labelInfomationOpCategory = ref(null)
        const labelInfomationOpSubCategory = ref(null)
        const labelInfomationOpDescription = ref(null)
        const labelInfomationOpMSROwner = ref(null)
        const labelInfomationOpTopic = ref(null)

        const labelInfomationOpObjective = ref(null)
        const labelInfomationOpMSRNumber = ref(null)
        const employees = ref([])
        const categories = ref([])
        const subCategories = ref([])
        // injectors
        const msrDropdowns: Ref = inject('controlDropdowns')
        const msrCategories: Ref = inject('controlCategories')

        // set category and sub category dropdown options
        const setCategorySubCategoryDropdown = () => {
            categories.value = []
            subCategories.value = []
            if (msrCategories.value.categories) categories.value = msrCategories.value.categories
            // remove null values from categories
            categories.value = categories.value.filter((val) => val !== null)
            // add Other option manually
            categories.value.push(t('OTHER', language.value))
            if (msrCategories.value.subCategories)
                subCategories.value = msrCategories.value.subCategories
            // remove null values from sub categories
            subCategories.value = subCategories.value.filter((val) => val !== null)
            // add Other option manually
            subCategories.value.push(t('OTHER', language.value))
        }

        const setDropdownValues = () => {
            // owner list should only contain the users with role as employee
            employees.value = []
            users.value &&
                users.value.map((user) => {
                    // get user logged in organisation(current org)
                    const currentOrganisation = user.organisations.filter(
                        (org) => org.id === Number(organisationId)
                    )
                    // get users who are available in current organisation with 'Employee' role and are not expired
                    const filteredUsers =
                        currentOrganisation[0] &&
                        !!currentOrganisation[0].roles.find(
                            (role) => role.name === UserRole.EMPLOYEE
                        ) &&
                        user.userExpired !== true
                    if (filteredUsers) {
                        employees.value.push(user)
                    }
                })

            if (msrCategories.value) {
                setCategorySubCategoryDropdown()
            }
        }

        // validate all fields when creating a new entity/ updating self created entity
        const validateAllFields = () => {
            // missingValue checks
            errors.value.NORM.missingValue = utils.validateFields(formData.value.NORM, 'number')

            errors.value.MSR_NUMBER.missingValue = utils.validateFields(
                formData.value.MSR_NUMBER,
                'string'
            )

            errors.value.TOPIC.missingValue = utils.validateFields(formData.value.TOPIC, 'string')
            errors.value.DESCRIPTION.missingValue = utils.validateFields(
                formData.value.DESCRIPTION,
                'string'
            )
            errors.value.OBJECTUVE.missingValue = utils.validateFields(
                formData.value.OBJECTUVE,
                'string'
            )
            errors.value.OWNER.missingValue = utils.validateFields(formData.value.OWNER, 'number')
        }

        const validateForm = () => {
            errors.value = {
                OWNER: { expire: false, missingValue: false },
                NORM: { missingValue: false },
                MSR_NUMBER: { missingValue: false },
                TOPIC: { missingValue: false },
                DESCRIPTION: { missingValue: false },
                OBJECTUVE: { missingValue: false },
                CATEGORY_TEXT: { alreadyExist: false },
                SUB_CATEGORY_TEXT: { alreadyExist: false },
            }
            // validate all fields when creating a new entity/ updating self created entity
            if (!props.entityDetails || props.createdByUser) validateAllFields()
            else {
                // if user is editing super admin fields, validate only superadmin fields
                /* This super admin and employee seperate validations had to add as some madatory data of system entered records
                 are missing in db.In order to avoid throwing validation errors while editing such data, this condition has added.
                 We can remove this once we have stable data in db. (after BE ticket #458 is completed)*/
                if (props.superAdminEdit) {
                    // check missing values

                    errors.value.MSR_NUMBER.missingValue = utils.validateFields(
                        formData.value.MSR_NUMBER,
                        'string'
                    )

                    errors.value.TOPIC.missingValue = utils.validateFields(
                        formData.value.TOPIC,
                        'string'
                    )
                    errors.value.DESCRIPTION.missingValue = utils.validateFields(
                        formData.value.DESCRIPTION,
                        'string'
                    )
                    errors.value.OBJECTUVE.missingValue = utils.validateFields(
                        formData.value.OBJECTUVE,
                        'string'
                    )
                } else {
                    // validate employee edit fields
                    errors.value.OWNER.missingValue = utils.validateFields(
                        formData.value.OWNER,
                        'number'
                    )
                }
            }
            /* check whether selected owner is expired or not.
            Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
            In this case, it will ask to select an active user
                */
            const owner = employees.value.find((employee) => employee.id === formData.value.OWNER)
            if (owner) errors.value.OWNER.expire = owner.userExpired

            // check weather the entered category(`CATEGORY_TEXT`) is already in the categories array
            if (formData.value.CATEGORY_TEXT)
                errors.value.CATEGORY_TEXT.alreadyExist = utils.checkItemAlreadyExists(
                    categories.value,
                    formData.value.CATEGORY_TEXT
                )
            // check weather entered sub category(`SUB_CATEGORY_TEXT`) is already in the subCategories array
            if (formData.value.SUB_CATEGORY_TEXT)
                errors.value.SUB_CATEGORY_TEXT.alreadyExist = utils.checkItemAlreadyExists(
                    subCategories.value,
                    formData.value.SUB_CATEGORY_TEXT
                )
        }

        // set form data
        const setFormData = () => {
            const msrDetails = props.entityDetails
            formData.value = {
                NORM: msrDetails.originMsrNorm && msrDetails.originMsrNorm.id,
                CATEGORY: msrDetails.category,
                SUB_CATEGORY: msrDetails.subCategory,
                DESCRIPTION: msrDetails.description,
                OWNER:
                    msrDetails.owner && msrDetails.owner.username !== variables.DEFAULT_USERNAME
                        ? msrDetails.owner.id
                        : userId,
                TOPIC: msrDetails.topic,
                OBJECTUVE: msrDetails.objective,
                CATEGORY_TEXT: '',
                SUB_CATEGORY_TEXT: '',
                MSR_NUMBER: msrDetails.msrNumber,
            }
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        const changeCategory = () => {
            formData.value.CATEGORY_TEXT = ''
            errors.value.CATEGORY_TEXT.alreadyExist = false
        }

        const changeSubCategory = () => {
            formData.value.SUB_CATEGORY_TEXT = ''
            errors.value.SUB_CATEGORY_TEXT.alreadyExist = false
        }

        onMounted(() => {
            setDropdownValues()
            if (props.entityDetails) {
                setFormData()
            }
        })
        // reset fields on cancel
        const cancelForm = () => {
            setFormData()
        }

        // clear form fields
        const clearFields = () => {
            formData.value = {
                NORM: 0,
                CATEGORY: '',
                SUB_CATEGORY: '',
                DESCRIPTION: '',
                OWNER: userId ? userId : 0,
                TOPIC: '',
                OBJECTUVE: '',
                CATEGORY_TEXT: '',
                SUB_CATEGORY_TEXT: '',
                MSR_NUMBER: '',
            }
        }

        // Fields which super admin can edit, will be view under following conditions
        const isSuperAdminViewFieldEnabled = computed(() => {
            return (
                !props.superAdminEdit &&
                !(props.createdByUser && props.editable) &&
                props.entityDetails
            )
        })

        // Fields which super admin can edit, will be editable under following conditions
        const isSuperAdminEditFieldEnabled = computed(() => {
            return (
                props.superAdminEdit ||
                (props.createdByUser && props.editable) ||
                !props.entityDetails
            )
        })

        // Fields which employee can edit, will be view under following conditions
        const isEmployeeViewFieldEnabled = computed(() => {
            return (
                !props.editable && props.entityDetails && !(props.createdByUser && props.editable)
            )
        })

        // Fields which employee can edit, will be editable under following conditions
        const isEmployeeEditFieldEnabled = computed(() => {
            return props.editable || !props.entityDetails || (props.createdByUser && props.editable)
        })

        // display norm under following conditions
        const isNormViewEnabled = computed(() => {
            return props.entityDetails && !(props.createdByUser && props.editable)
        })

        // display norm edit under following conditions
        const isNormEditEnabled = computed(() => {
            return !props.entityDetails || (props.createdByUser && props.editable)
        })

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return props.editable || props.superAdminEdit || !props.entityDetails
        })

        watch(
            () => props.entityDetails,
            () => {
                if (props.entityDetails) {
                    setDropdownValues()
                }
            },
            {
                immediate: true,
            }
        )

        // watch for language chaneges and update dropdowns
        watch(language, () => {
            setDropdownValues()
        })

        watch(
            () => msrCategories.value,
            () => {
                if (msrCategories.value) {
                    setCategorySubCategoryDropdown()
                }
            }
        )

        return {
            t,
            language,
            formData,
            users,
            labelInfomationOpNorms,
            labelInfomationOpCategory,
            labelInfomationOpSubCategory,
            labelInfomationOpDescription,
            labelInfomationOpMSROwner,
            labelInfomationOpTopic,
            labelInfomationOpObjective,
            toggleInformation,
            cancelForm,
            utils,
            variables,
            clearFields,
            employees,
            errors,
            validateForm,
            categories,
            subCategories,
            changeCategory,
            changeSubCategory,
            labelInfomationOpMSRNumber,
            isSuperAdminViewFieldEnabled,
            isSuperAdminEditFieldEnabled,
            isEmployeeViewFieldEnabled,
            isEmployeeEditFieldEnabled,
            isNormViewEnabled,
            isNormEditEnabled,
            isEditableMode,
            msrDropdowns,
        }
    },
})
