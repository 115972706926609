






































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, inject, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import ControlSection from '@/components/controls/ControlSection.vue'
import MSRAssessmentSection from '@/shared/components/assessments/AssessmentSection.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import MSRAssessmentApproval from '@/shared/components/assessments/AssessmentApproval.vue'
import utils from '@/shared/mixins/utils'
import { Status, UserRole } from '@/shared/enum/general-enum'
import generalData from '@/assets/data/general-data.json'
import variables from '@/shared/variables'
import Confirmation from '@/shared/components/Confirmation.vue'
import MSRActionPlan from '@/components/msr-assessments/actionplan/MSRActionPlan.vue'
import { Effectiveness } from '@/shared/enum/asset-control-assessment-enum'
import { Route } from 'vue-router'
import msrQueries from '@/shared/queries/msrQueries'
import { MSRAssessmentDTO } from '@/dto/backend-response/msrAssessmentsDTO'

const fetchMSRAssessment = async (id: number): Promise<MSRAssessmentDTO> => {
    const getMSRDetailsQuery = `
    query{
            msrAssessment(id:${id}){
                ${msrQueries.MSR_ASSESSMENT_DETAILS}
            }
        }
    `
    return apolloClient.getGraphqlData(getMSRDetailsQuery)
        .then(
            (result => result.data.msrAssessment)
        )
        .catch(
            () => {
                throw Error('Error white retrieving msr assessment details')
            }
        )
}


        


export default defineComponent({
    components: {
        ControlSection,
        MSRAssessmentSection,
        MSRAssessmentApproval,
        Confirmation,
        MSRActionPlan,
    },
    props: {
        msrId: {
            type: Number,
        },
        // Make previous,next buttons available if the component is accessed via a list
        accessType: {
            type: String,
        },
        //TODO: #803
        lastRecordNo: {},
        recordNo: {},
        msrAssessmentId: {
            type: Number,
        },
        firstRecordNo: {},
        newAssessmentSourceId: {
            type: Number,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const msrAssessmentSection = ref(null)
        // eslint-disable-next-line
        const msrDetails: Ref = ref(null)
        // eslint-disable-next-line
        const msrAssessmentDetails: Ref = ref(null)
        // TODO: Swap this DTO for one that exactly matches the Details query
        const newMSRAssessmentSource: Ref<MSRAssessmentDTO> = ref(null)
        const editable = ref(false)
        const toast = useToast()
        const enableApproval = ref(false)
        const msrAssessmentApproval = ref(null)
        const approvalContent = ref(null)
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const displayApproveConfirmation = ref(false)
        const displaydeclinedConfirmation = ref(false)
        const displaySubmitConfirmation = ref(false)
        const nameInitals = ref('')
        const displayActionplan = ref(false)
        const disable = ref(false)
        const originComponents = ref({
            msrAssessments: 'MSRAssessments',
            msrList: 'MSRequirements',
        })
        const routedFrom = ref('')
        const loading = ref(false)
        const orderNo = ref(null)
        const msrAssessmentInfobox = ref('')
        const VIEW_VALIDATION_MODE: Ref<boolean> = inject('VIEW_VALIDATION_MODE', ref(false))

        // button handle logically
        const buttonEnable = () => {
            if (msrAssessmentDetails.value) {
                editable.value = false
            } else {
                editable.value = true
            }
        }

        // get info box text based on navigated menu option
        const getMSRAssessmentInfoBoxText = (route: Route) => {
            msrAssessmentInfobox.value = utils.getInfoTextTranslationKeyFor(
                'msr-assessment', 
                route.name
            )
        }

        // go to record (previous/ next)
        const goToRecord = (type) => {
            emit('go-to-record', { no: props.recordNo, type })
        }

        // set assessment configurations based on response data
        const setAssessmentConfiguration = () => {
            // disable approval section to avoid approving their own action plan
            const username =
                msrAssessmentDetails.value.executedBy &&
                msrAssessmentDetails.value.executedBy.username
            disable.value = utils.disableApprovalSection(username)

            // set name with initials to display in avatar
            const fullNameOwner =
                (msrAssessmentDetails.value.executedBy &&
                    msrAssessmentDetails.value.executedBy.firstName) +
                ' ' +
                (msrAssessmentDetails.value.executedBy &&
                    msrAssessmentDetails.value.executedBy.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)

            const alertsMenuType: string = VIEW_VALIDATION_MODE.value ? 'menu-validation' : 'not-validation-mode'
            msrAssessmentDetails.value.color = utils.getAlertInfo(
                alertsMenuType,
                msrAssessmentDetails.value
            ).color
            msrAssessmentDetails.value.info = utils.getAlertInfo(
                alertsMenuType,
                msrAssessmentDetails.value
            ).info
            msrAssessmentDetails.value.infoKey = utils.getAlertInfo(
                alertsMenuType,
                msrAssessmentDetails.value
            ).infoKey
        }
        const clearMSRAssessment = () => {
            msrDetails.value = null
            newMSRAssessmentSource.value = null
            approvalContent.value = null
            enableApproval.value = false
        }

        // get msr details for the selected msr
        const getMSRAssessmentDetails = async () => {
            loading.value = true
            /* when action plan is created as soon as msr assessment is created  `props.msrAssessmentId` will be null. 
            Hence taking `msrAssessmentDetails.value.id` to get updated msr assessment data when action plan is created*/
            const assessmentId = props.msrAssessmentId ?? msrAssessmentDetails.value?.id
            fetchMSRAssessment(assessmentId)
                .then((assessment) => {
                    clearMSRAssessment()
                    msrAssessmentDetails.value = assessment
                    // set order no
                    orderNo.value = utils.padLeft('000', assessment.order)
                    // set msr details
                    msrDetails.value = assessment.msr

                    buttonEnable()
                    if (msrAssessmentDetails.value) {
                        setAssessmentConfiguration()
                    }
                    // check for approval content available
                    if (
                        msrAssessmentDetails.value &&
                        msrAssessmentDetails.value.validationBy &&
                        msrAssessmentDetails.value.validationBy.id
                    ) {
                        // set approval content
                        approvalContent.value = {
                            validationBy: msrAssessmentDetails.value.validationBy,
                            validationDate: msrAssessmentDetails.value.validationDate,
                            validationRemark: msrAssessmentDetails.value.validationRemark,
                        }
                    }

                    // empty approval content when assessment state is in submit state or in progress state
                    if (
                        msrAssessmentDetails.value &&
                        (msrAssessmentDetails.value.state === Status.submitted ||
                            msrAssessmentDetails.value.state === Status.inprogress)
                    ) {
                        approvalContent.value = null
                    }
                })
                .finally(() => loading.value = false)
        }

        // update msr assessment state
        const updateState = async (status: number): Promise<string> => {
            const inputStatus = {
                msrAssessmentState: status,
            }

            const returnQuery = `msrAssessment{
                ${msrQueries.MSR_ASSESSMENT_DETAILS}
            }`
            let resultOfStatus
            try {
                resultOfStatus = await utils.updateDocumentState(
                    msrAssessmentDetails.value.id,
                    returnQuery,
                    inputStatus
                )
            } catch (err) {
                return 'error'
            }

            if (resultOfStatus) {
                msrAssessmentDetails.value = resultOfStatus.msrAssessment
                setAssessmentConfiguration()
                emit('post-assessment-update')
                return 'success'
            }
            return 'error'
        }

        // cancel approval
        const cancelApproval = () => {
            msrAssessmentApproval.value && msrAssessmentApproval.value.cancelForm()
            enableApproval.value = false
            emit('close-assessment')
        }

        //  save msr assessment
        const saveMSRAssessment = async () => {
            msrAssessmentSection.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(msrAssessmentSection.value.errors)) {
                return
            }

            const msrId = msrAssessmentDetails.value
                ? msrAssessmentDetails.value.msr.id
                : Number(props.msrId)
            const msrAssessmentForm = msrAssessmentSection.value.assessmentForm

            //  if design or existence or op selected as `not tested` date fields are null
            if (msrAssessmentForm.DESIGN === Effectiveness.NOT_TESTED) {
                msrAssessmentForm.DESIGN_DATE = null
            }

            if (msrAssessmentForm.EXISTENCE === Effectiveness.NOT_TESTED) {
                msrAssessmentForm.EXISTENCE_DATE = null
            }

            if (msrAssessmentForm.OPERATIONAL_EFFECTIVENESS === Effectiveness.NOT_TESTED) {
                msrAssessmentForm.OP_START_DATE = null
                msrAssessmentForm.OP_END_DATE = null
            }

            const msrAssessmentData = {
                msr: msrId,
                opStartDate: msrAssessmentForm.OP_START_DATE,
                opEndDate: msrAssessmentForm.OP_END_DATE,
                executedBy: msrAssessmentForm.EXECUTED_BY,
                design: msrAssessmentForm.DESIGN,
                designSetDate: msrAssessmentForm.DESIGN_DATE,
                designRemark: msrAssessmentForm.DESIGN_REMARK,
                existence: msrAssessmentForm.EXISTENCE,
                existenceSetDate: msrAssessmentForm.EXISTENCE_DATE,
                existenceRemark: msrAssessmentForm.EXISTENCE_REMARK,
                operationalEffectiveness: msrAssessmentForm.OPERATIONAL_EFFECTIVENESS,
                operationalEffectivenessRemark: msrAssessmentForm.OPERATIONAL_EFFECTIVENESS_REMARK,
                overallConclusion:
                    msrAssessmentForm.OVERALL === Effectiveness.EFFECTIVE ? true : false,
                overallConclusionRemark: msrAssessmentForm.OVERALL_CONCLUSION_REMARK,
                maturity: msrAssessmentForm.MATURITY,
                maturityAmbition: msrAssessmentForm.MATURITY_AMBITION,
                remainingRiskAccepted:
                    msrAssessmentForm.REMAINING_RISK_ACCEPTED === 1 ? true : false,
                remainingRiskAcceptedRemark: msrAssessmentForm.REMAINING_RISK_ACCEPTED_REMARK,
                documentationUrl: msrAssessmentForm.DOCUMENTATION_URL,
                dateNextAssessment: msrAssessmentForm.DATE_NEXT_ASSESSMENT,
                actionplanRequired: msrAssessmentForm.ACTIONPLAN_REQUIRED === 1 ? true : false,
                actionplanExplanation: msrAssessmentForm.ACTIONPLAN_REMARK,
                executionDate: msrAssessmentForm.EXECUTION_DATE,
                assessmentExplanation: msrAssessmentForm.ASSESSMENT_EXPLANATION,
            }

            // mutation query
            let mutationQuery
            // create
            if (!msrAssessmentDetails.value) {
                mutationQuery = `mutation ($input: CreateUpdateMsrAssessmentInput!) {
                createMsrAssessment(input: $input) {
                        assessment{
                            ${msrQueries.MSR_ASSESSMENT_DETAILS}
                        }
                            status
                            error
                }
              }`
            } else {
                // update
                mutationQuery = `mutation ($input: CreateUpdateMsrAssessmentInput!) {
                    updateMsrAssessment(id:${msrAssessmentDetails.value.id}, input: $input) {
                            status
                            error
                }
              }`
            }

            let result
            try {
                // update data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, msrAssessmentData)
            } catch (err) {
                toast.error(t('MSR_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
                throw Error('Error while saving MSR assessment')
            }
            // create new
            if (result.data.createMsrAssessment && result.data.createMsrAssessment.status) {
                msrAssessmentDetails.value = result.data.createMsrAssessment.assessment

                // 2- in progress
                const status = await updateState(Status.inprogress)
                buttonEnable()
                if (status === 'success')
                    toast.success(t('MSR_ASSESSMENT_SAVED_SUCCESS_MESSAGE', language.value))
                else toast.error(t('MSR_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
            } else if (result.data.updateMsrAssessment && result.data.updateMsrAssessment.status) {
                //update
                // when resubmitting declined assessment, resetting approval section content and fields
                approvalContent.value = null
                // 2- in progress
                const status = await updateState(Status.inprogress)
                buttonEnable()

                if (status === 'success')
                    toast.success(t('MSR_ASSESSMENT_SAVED_SUCCESS_MESSAGE', language.value))
                else toast.error(t('MSR_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
            } else {
                toast.error(t('MSR_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
            }
        }

        //  on click of cancel button
        const cancel = () => {
            if (editable.value && msrAssessmentDetails.value) {
                // if the document is in edit mode, it will exit back to view
                editable.value = false
            } else {
                // if you are in create new form, form fields will be clear
                msrAssessmentSection.value.cancelForm()
            }
            emit('close-assessment')
        }

        // msr assessment approve
        const submitApprovalContent = async () => {
            // As reusing same msr assessment approval section
            const msrAssessmentApprovalForm = msrAssessmentApproval.value.assessmentApprovalForm

            // msr assessment approval input object
            const input = {
                validationBy: msrAssessmentApprovalForm.VALIDATION_BY,
                validationDate: msrAssessmentApprovalForm.VALIDATION_DATE,
                validationRemark: msrAssessmentApprovalForm.VALIDATION_REMARK,
            }

            // mutation query
            // approve
            const mutationQuery = `mutation ($input: MsrAssessmentApprovalInput!) {
                    approveMsrAssessment(id: ${msrAssessmentDetails.value.id}, input: $input) {
                        assessment{
                            ${msrQueries.MSR_ASSESSMENT_APPROVAL_DETAILS}
                        }

                        status
                        error
                    }   
                }`
            let result
            try {
                // approve data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                return 'error'
            }
            // check for success approval
            if (result.data.approveMsrAssessment && result.data.approveMsrAssessment.status) {
                const msrApproval = result.data.approveMsrAssessment.assessment

                // set approval content
                approvalContent.value = {
                    validationBy: msrApproval.validationBy,
                    validationDate: msrApproval.validationDate,
                    validationRemark: msrApproval.validationRemark,
                }

                return 'submitted'
            } else {
                return 'error'
            }
        }

        // on success of approval content submition update the state of assessment as approved
        const approveAssessment = async () => {
            // msr assessment
            const approval = await submitApprovalContent()
            if (approval === 'submitted') {
                // 4- approved
                const status = await updateState(Status.approved)
                if (status === 'success')
                    toast.success(t('MSR_ASSESSMENT_APPROVE_SUCCESS_MESSAGE', language.value))
                else toast.error(t('MSR_ASSESSMENT_APPROVE_ERROR_MESSAGE', language.value))
            } else {
                toast.error(t('MSR_ASSESSMENT_APPROVE_ERROR_MESSAGE', language.value))
            }
        }

        // on success of approval content submition update the state of assessment as declined
        const declineAssessment = async () => {
            // msr assessment
            const approval = await submitApprovalContent()
            if (approval === 'submitted') {
                // 5- declined
                const status = await updateState(Status.declined)
                if (status === 'success')
                    toast.success(t('MSR_ASSESSMENT_DECLINE_SUCCESS_MESSAGE', language.value))
                else toast.error(t('MSR_ASSESSMENT_DECLINE_ERROR_MESSAGE', language.value))
            } else {
                toast.error(t('MSR_ASSESSMENT_DECLINE_ERROR_MESSAGE', language.value))
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, Number(state))
            return t(stateVal, language.value)
        }

        // open save confirmation
        const openSaveConfirmation = () => {
            if (msrAssessmentDetails.value) {
                displaySaveConfirmation.value = true
            } else {
                saveMSRAssessment()
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            // cancel for edit assessment
            if (msrAssessmentDetails.value) {
                displayCancelConfirmation.value = true
            } else if (enableApproval.value) {
                // cancel for approval section
                cancelApproval()
            } else {
                // for new create entity
                displayCancelConfirmation.value = true
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            saveMSRAssessment()
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancel()
        }

        // on success submit confirm
        const successSubmitConfirmation = async () => {
            displaySubmitConfirmation.value = false
            enableApproval.value = true

            const status = await updateState(Status.submitted)
            if (status === 'success')
                toast.success(t('MSR_ASSESSMENT_SUBMIT_SUCCESS_MESSAGE', language.value))
            else toast.error(t('MSR_ASSESSMENT_SUBMIT_ERROR_MESSAGE', language.value))
        }
        // on success approve confirm
        const successApproveConfirmation = () => {
            displayApproveConfirmation.value = false
            msrAssessmentApproval.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(msrAssessmentApproval.value.errors)) {
                return
            }
            approveAssessment()
        }

        // on success decline confirm
        const successDeclinedConfirmation = () => {
            displaydeclinedConfirmation.value = false
            msrAssessmentApproval.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(msrAssessmentApproval.value.errors)) {
                return
            }
            declineAssessment()
        }

        // get msr details for the msr assessment
        const getMSRDetails = async () => {
            clearMSRAssessment()
            const getMSRDetailQuery = `
                query{
                   msr(id: ${props.msrId}){
                        ${msrQueries.MSR_ASSESSMENT_MSR_DETAILS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getMSRDetailQuery)
            } catch {
                throw Error('Error while retrieving msr details')
            }

            if (result) {
                msrDetails.value = result.data.msr
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close actionplan sidebar if it's open
                    if (displayActionplan.value) {
                        displayActionplan.value = false
                    }
                }
            })
        }

        // update msr assessments list and msr assessment details post action plan update
        const updateRecords = () => {
            getMSRAssessmentDetails()
            /* if user has routed from msr assessments list it should able to refresh assessments list in menu. 
            If user has routed from msr list page it should only refresh the assessments in tab (to avoid closing all tabs)*/
            if (routedFrom.value === originComponents.value.msrList) {
                emit('refresh-assessment')
            } else {
                emit('get-assessments')
            }
        }

        // enable submit button based on following conditions
        const isSubmitEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                !enableApproval.value &&
                !approvalContent.value &&
                msrAssessmentDetails.value &&
                (msrAssessmentDetails.value.state === Status.inprogress ||
                    msrAssessmentDetails.value.state === Status.declined) &&
                roles.includes(role.value)
            )
        })

        // enable approval buttons based on following conditions
        const isApprovalBtnsEnabled = computed(() => {
            const roles: string[] = [
                UserRole.VALIDATOR,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                ((enableApproval.value && !editable.value) ||
                    (msrAssessmentDetails.value &&
                        msrAssessmentDetails.value.state === Status.submitted) ||
                    enableApproval.value) &&
                roles.includes(role.value) &&
                !approvalContent.value
            )
        })

        // display approval content based on following conditions
        const displayApprovalContent = computed(() => {
            const roles: string[] = [
                UserRole.VALIDATOR,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                approvalContent.value ||
                (((enableApproval.value && !editable.value) ||
                    (msrAssessmentDetails.value &&
                        msrAssessmentDetails.value.state === Status.submitted)) &&
                    roles.includes(role.value))
            )
        })

        // display edit button based on following conditions
        const isEditEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                msrAssessmentDetails.value &&
                (msrAssessmentDetails.value.state === Status.inprogress ||
                    msrAssessmentDetails.value.state === Status.declined) &&
                roles.includes(role.value)
            )
        })

        // display create actionplan button based on following conditions
        const isCreateAPBtnEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                msrAssessmentDetails.value &&
                msrAssessmentDetails.value.actionplanRequired &&
                roles.includes(role.value) &&
                msrAssessmentDetails.value.actionPlans &&
                msrAssessmentDetails.value.actionPlans.length === 0
            )
        })

        // display view actionplan button based on following conditions
        const isViewAPBtnEnabled = computed(() => {
            return (
                !editable.value &&
                msrAssessmentDetails.value &&
                msrAssessmentDetails.value.actionplanRequired &&
                msrAssessmentDetails.value.actionPlans &&
                msrAssessmentDetails.value.actionPlans.length > 0
            )
        })

        // Create a new assessment based on data from an existing assessment
        const createMSRAssessmentFromSource = async (sourceId: number) => {
            loading.value = true
            fetchMSRAssessment(sourceId)
                .then((sourceAssessment) => {
                    clearMSRAssessment()
                    msrDetails.value = sourceAssessment.msr
                    newMSRAssessmentSource.value = sourceAssessment
                })
                .finally(() => loading.value = false)
        }

        //  watch for msrAssessmentId changes
        watch(
            [
                () => props.msrAssessmentId,
                () => props.newAssessmentSourceId,
            ],
            async ([assessmentId, newAssessmentSourceId]) => {
                if (assessmentId) {
                    getMSRAssessmentDetails()
                } else if (newAssessmentSourceId) {
                    await createMSRAssessmentFromSource(newAssessmentSourceId)
                } else {
                    /* since assessment is not available, get msr details separately using props.msrId.
                        When the assessment is available, can fetch msr details from assessment data itself. This will be only required
                        when user creates a new msr assessment */
                    getMSRDetails()
                }
            },
            { immediate: true }
        )
        onMounted(() => {
            closeSidebarEsc()
        })


        //  watch route path for decide which type of component we are on.
        watch(
            () => router.app.$route,
            () => {
                routedFrom.value = router.app.$route.name
                getMSRAssessmentInfoBoxText(router.app.$route)
            },
            {
                immediate: true,
            }
        )

        return {
            t,
            language,
            msrAssessmentSection,
            toast,
            msrDetails,
            msrAssessmentDetails,
            newMSRAssessmentSource,
            editable,
            getMSRAssessmentDetails,
            cancel,
            saveMSRAssessment,
            enableApproval,
            approveAssessment,
            msrAssessmentApproval,
            approvalContent,
            cancelApproval,
            updateState,
            infomationOp,
            toggleInformation,
            declineAssessment,
            Status,
            convertIdToNames,
            utils,
            variables,
            role,
            disable,
            openSaveConfirmation,
            openCancelConfirmation,
            successSaveConfirmation,
            successCancelConfirmation,
            displayCancelConfirmation,
            displaySaveConfirmation,
            nameInitals,
            displayApproveConfirmation,
            displaydeclinedConfirmation,
            successApproveConfirmation,
            successDeclinedConfirmation,
            displaySubmitConfirmation,
            successSubmitConfirmation,
            displayActionplan,
            originComponents,
            routedFrom,
            loading,
            VIEW_VALIDATION_MODE,
            updateRecords,
            UserRole,
            goToRecord,
            orderNo,
            msrAssessmentInfobox,
            isSubmitEnabled,
            isApprovalBtnsEnabled,
            isEditEnabled,
            displayApprovalContent,
            isCreateAPBtnEnabled,
            isViewAPBtnEnabled,
        }
    },
})
