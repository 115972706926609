import { render, staticRenderFns } from "./MSRTopSection.vue?vue&type=template&id=44e63611&scoped=true&"
import script from "./MSRTopSection.vue?vue&type=script&lang=ts&"
export * from "./MSRTopSection.vue?vue&type=script&lang=ts&"
import style0 from "./MSRTopSection.vue?vue&type=style&index=0&id=44e63611&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44e63611",
  null
  
)

export default component.exports