














































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { Ref, computed, defineComponent, inject, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import ActionPlanSection from '@/components/actionplan/ActionPlanSection.vue'
import ActionPlanApproval from '@/components/actionplan/ActionPlanApproval.vue'
import ControlSection from '@/components/actionplan/ControlSection.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import utils from '@/shared/mixins/utils'
import msrActionplanQuery from '@/shared/queries/msrActionplanQueries'
import { Status } from '@/shared/enum/general-enum'
import generalData from '@/assets/data/general-data.json'
import variables from '@/shared/variables'
import Confirmation from '@/shared/components/Confirmation.vue'
import { UserRole } from '@/shared/enum/general-enum'
import { Route } from 'vue-router'

export default defineComponent({
    components: {
        ActionPlanSection,
        ActionPlanApproval,
        ControlSection,
        Confirmation,
    },
    props: {
        msrAssessmentId: {},
        lastRecordNo: {},
        recordNo: {},
        actionplanId: {
            type: Number,
        },
        firstRecordNo: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const actionPlan = ref(null)
        const msrAssessmentDetails = ref(null)
        const enableApproval = ref(false)
        const approvalContent = ref(null)
        const ActionPlanSection = ref(null)
        const ActionPlanApproval = ref(null)
        const editable = ref(false)
        const toast = useToast()
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const disable = ref(false)
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const displayApproveConfirmation = ref(false)
        const displayReadyConfirmation = ref(false)
        const displaydeclinedConfirmation = ref(false)
        const displaySubmitConfirmation = ref(false)
        const nameInitals = ref('')
        const originComponents = ref({
            actionplan: 'MSRActionPlans',
            msrAssessment: 'MSRAssessments',
            msrList: 'MSRequirements',
        })
        const routedFrom = ref('')
        const loading = ref(false)
        const msrDetails = ref(null)
        const actionPlanInfoText = ref('')
        const VIEW_VALIDATION_MODE: Ref<boolean> = inject('VIEW_VALIDATION_MODE', ref(false))

        // go to record (previous/ next)
        const goToRecord = (type) => {
            emit('go-to-record', { no: props.recordNo, type })
        }

        const goBackToActionPlanList = () => {
            router.back()
        }

        // get info box text based on navigated menu option
        const getActionplanInfoBoxText = (route: Route) => {
            actionPlanInfoText.value = utils.getInfoTextTranslationKeyFor(
                'msr-action-plan', 
                route.name
            )
        }
        const buttonEnable = () => {
            if (actionPlan.value) {
                editable.value = false
            } else {
                editable.value = true
            }
        }

        const setActionPlanConfiguration = () => {
            const fullNameOwner =
                (actionPlan.value.owner && actionPlan.value.owner.firstName) +
                ' ' +
                (actionPlan.value.owner && actionPlan.value.owner.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)
            // Disable 'approval section' for users so they cannot approve their own submitted ActionPlans
            const submittedUser =
                actionPlan.value.submittedBy && actionPlan.value.submittedBy.username
            disable.value = utils.disableApprovalSection(submittedUser)
            const alertsMenuType: string = VIEW_VALIDATION_MODE.value ? 'menu-validation' : 'not-validation-mode'
            actionPlan.value.color = utils.getAlertInfo(alertsMenuType, actionPlan.value).color
            actionPlan.value.info = utils.getAlertInfo(alertsMenuType, actionPlan.value).info
            actionPlan.value.infoKey = utils.getAlertInfo(alertsMenuType, actionPlan.value).infoKey
        }

        const initialize = () => {
            msrAssessmentDetails.value = null
            actionPlan.value = null
            approvalContent.value = null
            enableApproval.value = false
            editable.value = false
        }

        // get action plan details
        const getActionplanDetails = async () => {
            initialize()
            loading.value = true
            const getMSRDetailQuery = `
                query{
                    msrActionPlan(id:${props.actionplanId}){
                        ${msrActionplanQuery.ACTION_PLAN_DETAILS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getMSRDetailQuery)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving msr actionplan details')
            }
            if (result) {
                const actioPlanResult = result.data.msrActionPlan
                actionPlan.value = actioPlanResult
                msrAssessmentDetails.value = actioPlanResult.msrAssessment
                msrDetails.value =
                    actioPlanResult.msrAssessment && actioPlanResult.msrAssessment.msr

                setActionPlanConfiguration()

                buttonEnable()
                // check for approval content available
                if (
                    actionPlan.value &&
                    (actionPlan.value.approver ||
                        actionPlan.value.approverDate ||
                        actionPlan.value.approverRemark)
                ) {
                    approvalContent.value = {
                        approver: actionPlan.value.approver,
                        approverDate: actionPlan.value.approverDate,
                        approverRemark: actionPlan.value.approverRemark,
                    }
                }

                // empty approval content when assessment state is in submit state or in progress state
                if (
                    actionPlan.value &&
                    (actionPlan.value.state === Status.submitted ||
                        actionPlan.value.state === Status.inprogress)
                ) {
                    approvalContent.value = null
                }
            }
        }

        // update assessment state
        const updateState = async (status: number): Promise<string> => {
            const inputStatus = {
                msrActionPlanState: status,
            }

            const returnQuery = `msrActionPlan{
                ${msrActionplanQuery.ACTION_PLAN_DETAILS}
            }`
            let resultOfStatus
            try {
                resultOfStatus = await utils.updateDocumentState(
                    actionPlan.value.id,
                    returnQuery,
                    inputStatus
                )
            } catch (err) {
                return 'error'
            }
            if (resultOfStatus) {
                actionPlan.value = resultOfStatus.msrActionPlan
                setActionPlanConfiguration()
                emit('update-records')
                return 'success'
            } else return 'error'
        }

        // cancel approval
        const cancelApproval = () => {
            ActionPlanApproval.value && ActionPlanApproval.value.cancelForm()
            enableApproval.value = false
            emit('close-actionplan')
        }

        const saveActionPlan = async () => {
            ActionPlanSection.value.validateForm()
            // check for validation errors
            if (utils.hasErrors(ActionPlanSection.value.errors)) {
                return
            }
            const actionPlanForm = ActionPlanSection.value.form

            const actionPlanData = {
                msrAssessment: msrAssessmentDetails.value.id,
                description: actionPlanForm.description,
                documentationUrl: actionPlanForm.documentationUrl,
                shortDescription: actionPlanForm.shortDescription,
                actionType: actionPlanForm.actionType,
                startDate: actionPlanForm.startDate,
                endDate: actionPlanForm.endDate,
                owner: actionPlanForm.owner,
                ownerRemark: actionPlanForm.ownerRemark,
            }

            // mutation query
            let mutationQuery
            let result
            // create
            if (!actionPlan.value) {
                mutationQuery = `mutation ($input: CreateUpdateMsrActionPlanInput!) {
                        createMsrActionPlan(input: $input) {
                            msrActionPlan {
                                ${msrActionplanQuery.ACTION_PLAN_DETAILS}
                            }
                            status
                            error
                        }
                      }
                      `
            } else {
                // update
                mutationQuery = `mutation ($input: CreateUpdateMsrActionPlanInput!) {
                        updateMsrActionPlan(id:${actionPlan.value.id}, input: $input) {
                            status
                            error
                        }
                      }`
            }

            try {
                // update data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, actionPlanData)
            } catch (err) {
                toast.error(t('MSR_ACTION_PLAN_SAVE_FAIL_MESSAGE', language.value))
                throw Error('Error while saving msr actionplan')
            }

            // create new
            if (result.data.createMsrActionPlan && result.data.createMsrActionPlan.status) {
                actionPlan.value = result.data.createMsrActionPlan.msrActionPlan

                // 2 - in progress
                const status = await updateState(Status.inprogress)
                buttonEnable()
                if (status === 'success')
                    toast.success(t('MSR_ACTION_PLAN_SAVE_SUCCESS_MESSAGE', language.value))
                else toast.error(t('MSR_ACTION_PLAN_SAVE_FAIL_MESSAGE', language.value))
            } else if (result.data.updateMsrActionPlan && result.data.updateMsrActionPlan.status) {
                //update
                let status
                // when user updates an already approved actionplan
                if (actionPlan.value.state === Status.approved) {
                    status = await updateState(Status.approved)
                } else {
                    approvalContent.value = null
                    status = await updateState(Status.inprogress)
                }
                buttonEnable()
                if (status === 'success')
                    toast.success(t('MSR_ACTION_PLAN_SAVE_SUCCESS_MESSAGE', language.value))
                else toast.error(t('MSR_ACTION_PLAN_SAVE_FAIL_MESSAGE', language.value))
            } else {
                toast.error(t('MSR_ACTION_PLAN_SAVE_FAIL_MESSAGE', language.value))
            }
        }

        //  on click of cancel button
        const cancel = () => {
            if (editable.value && actionPlan.value) {
                // if the document is in edit mode, it will exit back to view
                editable.value = false
            } else {
                // if you are in create new form, form fields will be clear
                ActionPlanSection.value.cancelForm()
            }
            emit('close-actionplan')
        }

        const submitApprovalContent = async () => {
            const actionPlanApprovalForm = ActionPlanApproval.value.actionPlanApprovalForm
            // approval input object
            const input = {
                approver: actionPlanApprovalForm.APPROVED_BY,
                approverDate: actionPlanApprovalForm.APPROVED_ON,
                approverRemark: actionPlanApprovalForm.APPROVAL_REMARK,
            }

            const approveQuery = `
                    mutation($input: MsrActionPlanApprovalInput!){
                      approveMsrActionPlan(id: ${actionPlan.value.id}, input: $input){
                        msrActionPlan{
                            ${msrActionplanQuery.ACTION_PLAN_APPROVAL_DETAILS}
                        }
                        status
                        error
                      }
                    }
                    `
            let result
            try {
                // approve data api call
                result = await apolloClient.updateGraphqlData(approveQuery, input)
            } catch (err) {
                return 'error'
            }
            // check for success approval
            if (result.data.approveMsrActionPlan && result.data.approveMsrActionPlan.status) {
                const returnedAP = result.data.approveMsrActionPlan.msrActionPlan

                // set approval content
                approvalContent.value = {
                    approver: returnedAP.approver,
                    approverDate: returnedAP.approverDate,
                    approverRemark: returnedAP.approverRemark,
                }

                return 'submitted'
            } else {
                return 'error'
            }
        }

        // on success of approval content submition update the state of action plan as approved
        const approveActionPlan = async () => {
            const approval = await submitApprovalContent()
            if (approval === 'submitted') {
                // 4- approved
                const status = await updateState(Status.approved)
                if (status === 'success')
                    toast.success(t('MSR_ACTION_PLAN_APPROVAL_SUCCESS_MESSAGE', language.value))
                else toast.error(t('MSR_ACTION_PLAN_APPROVAL_FAIL_MESSAGE', language.value))
            } else {
                toast.error(t('MSR_ACTION_PLAN_APPROVAL_FAIL_MESSAGE', language.value))
            }
        }

        // on success of approval content submition update the state of action plan as approved
        const makeReadyActionPlan = async () => {
            const status = await updateState(Status.ready)
            if (status === 'success')
                toast.success(t('MSR_ACTION_PLAN_READY_SUCCESS_MESSAGE', language.value))
            else toast.error(t('MSR_ACTION_PLAN_READY_FAIL_MESSAGE', language.value))
        }

        // on success of approval content submition update the state of action plan as declined
        const declineActionplan = async () => {
            const approval = await submitApprovalContent()
            if (approval === 'submitted') {
                // 5- declined
                const status = await updateState(Status.declined)
                if (status === 'success')
                    toast.success(t('MSR_ACTION_PLAN_DECLINE_SUCCESS_MESSAGE', language.value))
                else toast.error(t('MSR_ACTION_PLAN_DECLINE_ERROR_MESSAGE', language.value))
            } else {
                toast.error(t('MSR_ACTION_PLAN_DECLINE_ERROR_MESSAGE', language.value))
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // convert id values to name values
        const convertIdToNames = (state) => {
            const stateVal = utils.idToName(generalData.STATUS, state)
            /* if the status is in progress (translation variable `GENERAL_DOCUMENT_STATUS_2`), use action plan 
            specific inprogress(status 2) translation. (FE ticket #631) */
            if (stateVal === 'GENERAL_DOCUMENT_STATUS_2') {
                return t('ACTIONPLAN_STATUS_INPROGRESS', language.value)
            }
            return t(stateVal, language.value)
        }

        // open save confirmation
        const openSaveConfirmation = () => {
            if (actionPlan.value) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                saveActionPlan()
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            // cancel for edit assessment
            if (actionPlan.value) {
                displayCancelConfirmation.value = true
            } else if (enableApproval.value) {
                // cancel for approval section
                cancelApproval()
            } else {
                // for new create entity
                displayCancelConfirmation.value = true
            }
        }

        // save confirm-dialog, on success
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            saveActionPlan()
        }

        // cancel confirm-dialog, on success
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancel()
        }

        // submit confirm-dialog, on success
        const successSubmitConfirmation = async () => {
            displaySubmitConfirmation.value = false
            enableApproval.value = true
            const status = await updateState(Status.submitted)
            if (status === 'success')
                toast.success(t('MSR_ACTION_PLAN_SUBMIT_SUCCESS_MESSAGE', language.value))
            else toast.error(t('MSR_ACTION_PLAN_SUBMIT_FAIL_MESSAGE', language.value))
        }

        // approve confirm-dialog, on success
        const successApproveConfirmation = () => {
            displayApproveConfirmation.value = false
            // validate form
            ActionPlanApproval.value.validateForm()
            // check for validation errors
            if (utils.hasErrors(ActionPlanApproval.value.errors)) {
                return
            }
            approveActionPlan()
        }

        // ready confirm-dialog, on success
        const successReadyConfirmation = () => {
            // close ready confirmation-box.
            displayReadyConfirmation.value = false
            // approval process is completed with ready state.
            enableApproval.value = false
            makeReadyActionPlan()
        }

        // decline confirm-dialog, on success
        const successDeclinedConfirmation = () => {
            // close decline confirmation-box.
            displaydeclinedConfirmation.value = false
            // approval process is no longer required until updated.
            enableApproval.value = false
            // validate form
            ActionPlanApproval.value.validateForm()
            // check for validation errors
            if (utils.hasErrors(ActionPlanApproval.value.errors)) {
                return
            }
            declineActionplan()
        }

        const getMSRAndAssessmentDetails = async () => {
            initialize()
            loading.value = true
            const getMsrDetailQuery = `
                query{
                    msrAssessment(id:${props.msrAssessmentId}){
                        ${msrActionplanQuery.MSR_ASSESSMENT}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getMsrDetailQuery)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving msr assessment')
            }
            if (result) {
                msrAssessmentDetails.value = result.data.msrAssessment
                msrDetails.value = msrAssessmentDetails.value && msrAssessmentDetails.value.msr
            }
        }

        // display edit button based on following conditions
        const isEditBtnEnabled = computed(() => {
            return (
                !editable.value &&
                actionPlan.value &&
                (actionPlan.value.state === Status.inprogress ||
                    actionPlan.value.state === Status.declined ||
                    actionPlan.value.state === Status.approved) &&
                (role.value === UserRole.EMPLOYEE ||
                    role.value === UserRole.PERIUM_ADMIN ||
                    role.value === UserRole.DEVELOPER_ADMIN)
            )
        })

        // display approval buttons based on following conditions
        const isApprovalBtnEnabled = computed(() => {
            return (
                ((actionPlan.value &&
                    // only at submit state, an actionplan an be approved or declined
                    actionPlan.value.state === Status.submitted) ||
                    enableApproval.value) &&
                (role.value === UserRole.VALIDATOR ||
                    role.value === UserRole.PERIUM_ADMIN ||
                    role.value === UserRole.DEVELOPER_ADMIN) &&
                !approvalContent.value
            )
        })

        // display ready buttons based on following conditions
        const isReadyBtnEnabled = computed(() => {
            return (
                actionPlan.value &&
                // ready button is only available if the status is approved
                actionPlan.value.state === Status.approved &&
                (role.value === UserRole.EMPLOYEE ||
                    role.value === UserRole.PERIUM_ADMIN ||
                    role.value === UserRole.DEVELOPER_ADMIN) &&
                !editable.value
            )
        })

        // display submit button based on following conditions
        const isSubmitEnabled = computed(() => {
            return (
                !editable.value &&
                !enableApproval.value &&
                !approvalContent.value &&
                actionPlan.value &&
                (actionPlan.value.state === Status.inprogress ||
                    actionPlan.value.state === Status.declined) &&
                (role.value === UserRole.EMPLOYEE ||
                    role.value === UserRole.PERIUM_ADMIN ||
                    role.value === UserRole.DEVELOPER_ADMIN)
            )
        })

        // display approval content based on following conditions
        const displayApprovalContent = computed(() => {
            return (
                approvalContent.value ||
                (((enableApproval.value && !editable.value) ||
                    (actionPlan.value && actionPlan.value.state === Status.submitted)) &&
                    (role.value === UserRole.VALIDATOR ||
                        role.value === UserRole.PERIUM_ADMIN ||
                        role.value === UserRole.DEVELOPER_ADMIN))
            )
        })

        //  watch for actionplanId changes
        watch(
            () => props.actionplanId,
            () => {
                if (props.actionplanId) {
                    getActionplanDetails()
                } else {
                    getMSRAndAssessmentDetails()
                }
            },
            { immediate: true }
        )

        //  watch route path for decide which type of component we are on.
        watch(
            () => router.app.$route,
            () => {
                routedFrom.value = router.app.$route.name
                getActionplanInfoBoxText(router.app.$route)
            },
            {
                immediate: true,
            }
        )

        return {
            actionPlan,
            ActionPlanSection,
            ActionPlanApproval,
            approveActionPlan,
            approvalContent,
            cancel,
            cancelApproval,
            msrAssessmentDetails,
            enableApproval,
            goBackToActionPlanList,
            language,
            editable,
            saveActionPlan,
            t,
            toast,
            updateState,
            infomationOp,
            toggleInformation,
            makeReadyActionPlan,
            declineActionplan,
            Status,
            convertIdToNames,
            utils,
            role,
            variables,
            disable,
            displayApproveConfirmation,
            displayReadyConfirmation,
            displaydeclinedConfirmation,
            successApproveConfirmation,
            successReadyConfirmation,
            successDeclinedConfirmation,
            displaySubmitConfirmation,
            successSubmitConfirmation,
            successSaveConfirmation,
            successCancelConfirmation,
            openCancelConfirmation,
            openSaveConfirmation,
            displaySaveConfirmation,
            displayCancelConfirmation,
            nameInitals,
            originComponents,
            routedFrom,
            goToRecord,
            VIEW_VALIDATION_MODE,
            loading,
            msrDetails,
            UserRole,
            actionPlanInfoText,
            isEditBtnEnabled,
            isApprovalBtnEnabled,
            isReadyBtnEnabled,
            isSubmitEnabled,
            displayApprovalContent,
        }
    },
})
