const MSR_LIST = `
    id
    order
    alertColor{
        color
        info
        infoKey
    }
    category
    msrNumber
    description
    topic
    owner{
        id
        firstName
        lastName
        username
    }
    originMsrNorm{
        name
    }
    assessments{
        id
        maturity
    }
    `

// direct access msr details from msr list
const MSR_LIST_MSR_DETAILS = `
        id
        order
        alertColor{
            info
            color
            infoKey
        }
        category
        subCategory
        msrNumber
        topic
        description
        owner{
            id
            username
            firstName
            lastName           
        }
        created_by{
            id
            username
        }
        objective
        consideration
        implementationGuide
        originMsrNorm{
            id
            name
        }
        process
        implementationGuideAdditionalInformation
        executionType
        assessmentIntervalUnit
        assessmentIntervalValue
        executionIntervalValue
        executionIntervalUnit
        msrExplanation
        documentationUrl
        executionGuide
        assessments{
            id
        }
        templates{
            id
            file
            filename
            displayName
            documentType
        }
    `

//  msr assessments list
const MSR_ASSESSMENTS_LIST = `
    id
    order
    refId
    alertColor{
        color
        info
        infoKey
    }
    validationAlertColor{
        color
        info
        infoKey
    }
    msr{
        id
        description
        category
        topic
        msrNumber
    }
    executedBy {
        username
        lastName
        firstName
    }
    dateNextAssessment
    overallConclusion
    state
    validationBy {
        username
        lastName
        firstName
    }
    validationDate
    `

const MSR_ASSESSMENTS_LIST_TAB = `
    id
    order
    refId
    msr {
        id
    }
    state
    opStartDate
    opEndDate
    design
    existence
    maturity
    operationalEffectiveness
    executedBy{
        username
        firstName
        lastName
        id
    }
    executionDate
`

// msr assessment query
const MSR_ASSESSMENT_DETAILS = `
    id
    refId
    order
    state
    alertColor{
        color
        info
        infoKey
    }
    validationAlertColor{
        color
        info
        infoKey
    }
    opStartDate
    opEndDate
    design
    designSetDate
    designRemark
    existence
    existenceSetDate
    existenceRemark
    operationalEffectiveness
    operationalEffectivenessRemark
    overallConclusion
    overallConclusionRemark
    maturity
    maturityAmbition
    remainingRisk
    remainingRiskAccepted
    remainingRiskAcceptedRemark
    documentationUrl
    dateNextAssessment
    actionplanRequired
    actionplanExplanation
    assessmentExplanation
    executedBy{
        username
        firstName
        lastName
        id
    }
    executionDate
    validationBy{
        id
        username
        firstName
        lastName
    }
    validationDate
    validationRemark
    actionPlans{
        id
    }
    msr{
        id
        category
        topic
        msrNumber
        assessmentIntervalUnit
        assessmentIntervalValue
        executionIntervalValue
        executionIntervalUnit
        owner{
            id
            firstName
            lastName
            username
        }
    }
    lastModified
    `

const CATEGORIES_SUBCATEGORIES = `
    categories
    subCategories
    `

const MSR_ASSESSMENT_MSR_DETAILS = `
    id
    category
    topic
    msrNumber
    assessmentIntervalUnit
    assessmentIntervalValue
    executionIntervalValue
    executionIntervalUnit
`
// msr assessment approval query
const MSR_ASSESSMENT_APPROVAL_DETAILS = `
    id
    state
    validationBy{
        id
        username
        firstName
        lastName
    }
    validationDate
    validationRemark
    `
export default {
    MSR_LIST,
    MSR_LIST_MSR_DETAILS,
    MSR_ASSESSMENTS_LIST,
    MSR_ASSESSMENTS_LIST_TAB,
    MSR_ASSESSMENT_DETAILS,
    CATEGORIES_SUBCATEGORIES,
    MSR_ASSESSMENT_MSR_DETAILS,
    MSR_ASSESSMENT_APPROVAL_DETAILS,
}
