
















































































































import { defineComponent, onMounted, provide, ref, Ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { useToast } from 'vue-toastification/composition'
import msrQueries from '@/shared/queries/msrQueries'
import MSROperations from '@/components/management-system/MSROperations.vue'
import { MSRDTO } from '@/dto/backend-response/msrDTO'
import variables from '@/shared/variables'
import { MSRFormDataDTO } from '@/dto/forms/msrFormDTO'
import msrData from '@/assets/data/msr-data.json'
import { UserRole } from '@/shared/enum/general-enum'
import { exportTable } from '@/shared/services/TableExport'

export default defineComponent({
    components: {
        Table,
        MSROperations,
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { menuOpen } = useState(['menuOpen'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const msrList = ref([])
        const loading = ref(false)
        const toast = useToast()
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const filterCount = ref(0)
        const infomationOp = ref(null)
        const showMSRDetails = ref(false)
        const msrDetails = ref(null)
        const tableCmp = ref(null)
        const msrCategories = ref([])
        const msrFilters = msrData.MSR_FILTERS
        const { role } = useState(['role'])

        // providers
        provide('controlDropdowns', {})
        provide('controlCategories', msrCategories)

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('MANAGEMENT_SYSTEM_REQUIREMENTS_COLUMN_MSR_NUMBER', language.value),
                    naturalCompare: true,
                    fieldName: 'msrNumber',
                    style: 'min-width: 200px;',
                    alert: true,
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_NORM', language.value),
                    sort: true,
                    style: 'width: 200px;',
                    fieldName: 'norm',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_TOPIC', language.value),
                    sort: true,
                    limit: 250,
                    fieldName: 'topic',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_DESCRIPTION', language.value),
                    sort: true,
                    limit: 200,
                    fieldName: 'description',
                    style: 'min-width: 400px;',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_CATEGORY', language.value),
                    fieldName: 'category',
                    naturalCompare: true,
                },
                {
                    header: t('CONTROLS_TABLE_MATURITY_LEVEL', language.value),
                    fieldName: 'maturity',
                    sort: true,
                },
            ]
        }

        // get the maturity of latest msr assessment(based on executionDate)
        const getMaturity = (assessments) => {
            if (assessments && assessments.length > 0) {
                const sortedByExecutionDate = assessments.sort((a, b) =>
                    a.executionDate < b.executionDate ? 1 : -1
                )
                const maturityOfLatestAssessmet =
                    sortedByExecutionDate[0] && sortedByExecutionDate[0].maturity
                return maturityOfLatestAssessmet
            }
            return '-'
        }

        // updating msrList array with additional fields and setting description content
        const toTableFormat = (msrDataList: Array<MSRDTO>) => {
            msrList.value = []
            msrDataList.map((msrData) => {
                let msr = {
                    no: 0,
                    msrNumber: msrData.msrNumber.replace(/\s+/g, ''),
                    status: '',
                    color: '',
                    id: 0,
                    fowner: '',
                    norm: '',
                    maturity: 0,
                    info: '',
                    infoKey: '',
                    description: '',
                    topic: '',
                    category: ''
                }
                // creating a copy of msr object
                msr = Object.create(msrData)
                msr.no = utils.padLeft('000', msrData.order)
                msr.color = utils.getAlertInfo('', msrData).alertColorName
                msr.info = utils.getAlertInfo('', msrData).info
                msr.infoKey = utils.getAlertInfo('', msrData).infoKey
                msr.fowner = msrData.owner.firstName + ' ' + msrData.owner.lastName
                msr.id = msrData.id
                msr.norm = msrData.originMsrNorm && msrData.originMsrNorm.name
                msr.maturity = getMaturity(msrData.assessments)
                msr.description = msrData.description
                msr.topic = msrData.topic
                msr.category = msrData.category
                msrList.value.push(msr)
            })
            msrList.value = utils.naturalCompare(msrList.value, 'msrNumber')
        }

        const getMSRList = async () => {
            loading.value = true
            const getMSRQuery = `
                query msrs($perPage:Int, $pageNum:Int){
                    msrs(perPage:$perPage, pageNum:$pageNum){
                        pageInfo{
                            totalPages
                            totalCount
                        }
                        items{
                            ${msrQueries.MSR_LIST}
                        }
                    }
                }
            `
            // variable params for query
            const msrVariables = {
                perPage: variables.LIST_ITEMS_PER_PAGE,
                pageNum: 1,
            }
            let result
            try {
                result = await apolloClient.getGraphqlData(getMSRQuery, msrVariables)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving msr data')
            }
            if (result) {
                // Copy array to be able to sort it in place
                const msrs = [...result.data.msrs.items]
                // display totale records count as filteredRecordCount. This could change based on client's comments in future.
                filteredRecordCount.value = result.data.msrs.pageInfo.totalCount
                toTableFormat(msrs)
            }
        }

        const getFormatedMsrDetails = () => {
            // set alert color and info
            msrDetails.value.color = utils.getAlertInfo('', msrDetails.value).color
            msrDetails.value.info = utils.getAlertInfo('', msrDetails.value).info
            msrDetails.value.infoKey = utils.getAlertInfo('', msrDetails.value).infoKey

            msrDetails.value.no = utils.padLeft('000', msrDetails.value.order)
            msrDetails.value.lastRecordNo =
                tableCmp.value && utils.getLastRecord(tableCmp.value.copyOfTableData)
            msrDetails.value.firstRecordNo = tableCmp.value && tableCmp.value.copyOfTableData[0].no
        }

        // get msr details for id
        const getMSRDetails = async (msrId: number) => {
            msrDetails.value = null
            const getMSRDetailQuery = `
                query{
                    msr(id:${msrId}){
                        ${msrQueries.MSR_LIST_MSR_DETAILS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getMSRDetailQuery)
            } catch {
                throw Error('Error while retrieving msr details')
            }

            if (result) {
                const msrDetailsResult = result.data.msr
                msrDetails.value = msrDetailsResult
                getFormatedMsrDetails()
            }
        }
        const openMSRDetailsPopup = async (id) => {
            showMSRDetails.value = true
            await getMSRDetails(id)
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // update table records count
        const updateRecordCount = (recordsCount) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // go to msr record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `msrList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // use the id of the record to get next/previous details
                await getMSRDetails(record.id)
            }
        }

        // MSR input for master updates
        const getMasterMSRInput = (msrFormData: MSRFormDataDTO): {} => {
            const category =
                msrFormData.CATEGORY === t('OTHER', language.value)
                    ? msrFormData.CATEGORY_TEXT.trim()
                    : msrFormData.CATEGORY
            const subCategory =
                msrFormData.SUB_CATEGORY === t('OTHER', language.value)
                    ? msrFormData.SUB_CATEGORY_TEXT.trim()
                    : msrFormData.SUB_CATEGORY
            return {
                msrNumber: msrFormData.MSR_NUMBER,
                subCategory: subCategory,
                category: category,
                topic: msrFormData.TOPIC,
                description: msrFormData.DESCRIPTION,
                objective: msrFormData.OBJECTUVE,
                consideration: msrFormData.CONSIDERATION,
                implementationGuide: msrFormData.IMPLEMENTATION_GUIDE,
                implementationGuideAdditionalInformation: msrFormData.ADDITIONAL_INFORMATION,
            }
        }

        // get categories and sub categories
        const getCategoriesAndSubCategories = async () => {
            const clientConfig = `
                query{
                    categories(type: "msr"){
                        ${msrQueries.CATEGORIES_SUBCATEGORIES}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(clientConfig)
            } catch {
                throw Error('Error while retrieving categories')
            }

            if (result) {
                msrCategories.value = result.data.categories
            }
        }

        const getUserMSRInout = (msrFormData: MSRFormDataDTO) => {
            // other user form data
            return {
                owner: msrFormData.OWNER,
                process: msrFormData.PROCESS,
                executionIntervalUnit: msrFormData.EXECUTION_INTERVAL_UNIT,
                executionIntervalValue: Number(msrFormData.EXECUTION_INTERVAL_VALUE),
                assessmentIntervalUnit: msrFormData.ASSESSMENT_INTERVAL_UNIT,
                assessmentIntervalValue: Number(msrFormData.ASSESSMENT_INTERVAL_VALUE),
                executionGuide: msrFormData.EXECUTION_GUIDE,
                executionType: msrFormData.EXECUTION_TYPE === 0 ? null : msrFormData.EXECUTION_TYPE,
                msrExplanation: msrFormData.MSR_EXPLANATION,
                documentationUrl: msrFormData.DOCUMENTATION_URL,
            }
        }

        // save while table list is sorted
        const sortableListSave = () => {
            // if the table is sorted (`sortedField` has a value)
            if (tableCmp.value.sortedField) {
                // Based on the sorted type(asc/desc), sort the refreshed list after saving
                if (tableCmp.value.sortToggle === 'up') {
                    msrList.value.sort((a, b) =>
                        a[tableCmp.value.sortedField] < b[tableCmp.value.sortedField] ? 1 : -1
                    )
                } else {
                    msrList.value.sort((a, b) =>
                        b[tableCmp.value.sortedField] < a[tableCmp.value.sortedField] ? 1 : -1
                    )
                }
            }
        }

        //  save msr details
        const saveMSRDetails = async (params: {
            id: number;
            formData: MSRFormDataDTO;
            createdBy: boolean;
            typeOfSave: string;
            no: string;
        }) => {
            const msrFormData = params.formData
            let mutationQuery
            let input
            // mutation query for super admin
            if (role.value === UserRole.SUPER_ADMIN) {
                mutationQuery = `mutation ($input: UpdateMasterMsrInput!) {
                        updateMasterMsr(id: ${params.id}, input: $input) {
                            status
                            error
                        }
                    }`
                input = getMasterMSRInput(msrFormData)
            } else {
                // mutation query for other users
                mutationQuery = `mutation ($input: UpdateMsrInput!) {
                    updateMsr(id:${params.id}, input: $input) {
                            status
                            error
                        }
                    }`

                input = getUserMSRInout(msrFormData)
            }

            let result
            try {
                // update data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                // msr save error
                toast.error(t('MSR_DETAILS_SAVED_ERROR_MESSAGE', language.value))
                throw Error('Error while saving MSR data')
            }
            // update msr success
            // update control success
            if (result.data.updateMsr && result.data.updateMsr.status) {
                await getMSRList()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.typeOfSave === 'save-next') {
                    await goToRecord({ no: params.no, type: 'next' })
                } else {
                    await getMSRDetails(params.id)
                }
                toast.success(t('MSR_DETAILS_SAVED_SUCCESS_MESSAGE', language.value))
            } else if (
                // update super admin success
                result.data.updateMasterMsr &&
                result.data.updateMasterMsr.status
            ) {
                await getMSRList()
                sortableListSave()
                getCategoriesAndSubCategories()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.typeOfSave === 'save-next') {
                    await goToRecord({ no: params.no, type: 'next' })
                } else {
                    await getMSRDetails(params.id)
                }
                toast.success(t('MSR_DETAILS_SAVED_SUCCESS_MESSAGE', language.value))
            } else {
                // msr save error
                toast.error(t('MSR_DETAILS_SAVED_ERROR_MESSAGE', language.value))
            }
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                const seondSidebar = document.querySelector('.second-sidebar')
                if (event.key === 'Escape') {
                    //close view details entity if it's open
                    if (showMSRDetails.value && !seondSidebar) {
                        showMSRDetails.value = false
                    }
                }
            })
        }

        // export all msr details
        const exportAllMsrDetails = async () => {
            try {
                loading.value = true
                const date = new Date().toLocaleDateString('nl')
                const fileName = `perium-msr-${date}`

                await exportTable(
                    fileName,
                    [
                        ...tableHeaders.value,
                        { header: 'Alert Color', fieldName: 'color' },
                        { header: 'Alert Message', fieldName: 'alert' },
                    ],
                    msrList.value.map((row) => ({
                        ...row,
                        msrNumber: row.msrNumber.replace(/\s+/g, ''),
                        norm: row.norm.replace(/_/g, ''),
                        alert: t(row.infoKey, language),
                    }))
                )
                toast.success(t('MSR_EXPORT_SUCCESS_TOAST'))
            } catch (err) {
                toast.error(t('MSR_EXPORT_FAILED_TOAST'))
                // Re-throw error for Sentry
                // Cast Error to any because old typescript does not know about
                // the options parameter. Can be removed one typescipt is updated.
                throw (Error as any)('Error while exporting msr details', {
                    cause: err,
                })
            } finally {
                loading.value = false
            }
        }

        onMounted(async () => {
            closeSidebarEsc()
            await getMSRList()
            getCategoriesAndSubCategories()
            setTableHeaders()
        })

        return {
            language,
            t,
            SET_LEFT_MENU_SIDEBAR,
            SET_OPEN_MENU,
            utils,
            menuOpen,
            tableHeaders,
            msrList,
            openMSRDetailsPopup,
            filteredRecordCount,
            searchValue,
            searchCount,
            updateRecordCount,
            setCount,
            toggleInformation,
            infomationOp,
            showMSRDetails,
            tableCmp,
            msrDetails,
            getMSRList,
            goToRecord,
            loading,
            saveMSRDetails,
            getCategoriesAndSubCategories,
            msrCategories,
            viewFilterSection,
            filterCount,
            msrFilters,
            exportAllMsrDetails
        }
    },
})
