









































































































































































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, inject, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import moment from 'moment'
import controldetailsDropdownData from '@/assets/data/controls-data.json'
import { TranslateResult } from 'vue-i18n'
import utils from '@/shared/mixins/utils'
import BIVTable from '@/shared/components/BIVTable.vue'
import { YesNo } from '@/shared/enum/general-enum'
import DocumentationLink from '@/shared/components/DocumentationLink.vue'
import TemplateDocs from '@/components/controls/TemplateDocs.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        editable: {
            type: Boolean,
        },
        superAdminEdit: {
            type: Boolean,
        },
        createdByUser: {
            type: Boolean,
        },
    },
    components: {
        BIVTable,
        DocumentationLink,
        TemplateDocs
    },
    setup(props) {
        const { t, tc } = useI18n()
        const { language } = useState(['language'])
        const executionTypeDropdow = ref([])
        const intervalDropDown = ref([])
        const textType = ref(null)
        const formData = ref({
            PROCESS: '',
            EXECUTION_TYPE: null,
            EXECUTION_GUIDE: '',
            MSR_EXPLANATION: '',
            EXECUTION_INTERVAL_UNIT: 0,
            EXECUTION_INTERVAL_VALUE: 0,
            ASSESSMENT_INTERVAL_UNIT: 0,
            ASSESSMENT_INTERVAL_VALUE: 0,
            IMPLEMENTATION_GUIDE: '',
            ADDITIONAL_INFORMATION: '',
            CONSIDERATION: '',
            DOCUMENTATION_URL: '',
        })
        const executionTypeName: Ref<TranslateResult> = ref('')
        const exPeriodUnitName: Ref<string> = ref('')
        const exAssessmentUnitName: Ref<string> = ref('')

        const labelInfomationOpProcess = ref(null)
        const labelInfomationOpConsiderations = ref(null)
        const labelInfomationOpImplementationGuide = ref(null)
        const labelInfomationOpAdditionalInfomation = ref(null)
        const labelInfomationOpExecutionType = ref(null)
        const labelInfomationOpExecutionGuide = ref(null)
        const labelInfomationOpExecutionMSRPeriod = ref(null)
        const labelInfomationOpExecutionAssessment = ref(null)
        const labelInfomationOpNote = ref(null)
        const labelInfomationOpDocumentationUrl = ref(null)
        const errors = ref({
            PROCESS: { missingValue: false },
            EXECUTION_INTERVAL_UNIT: { missingValue: false },
            EXECUTION_INTERVAL_VALUE: { missingValue: false },
            ASSESSMENT_INTERVAL_UNIT: { missingValue: false },
            ASSESSMENT_INTERVAL_VALUE: { missingValue: false },
        })

        // injectors
        const msrDropdowns: Ref = inject('controlDropdowns')

        // execution type droodown
        const setExecutionTypeDropdownValues = () => {
            executionTypeDropdow.value = [{ id: 0, value: '-' }]

            controldetailsDropdownData.EXECUTION_TYPE.map((execution) => {
                executionTypeDropdow.value.push({
                    id: execution.ID,
                    value: t(execution.VALUE, language.value),
                })
            })
        }

        // interval dropdown
        const setIntervalDropdownValues = () => {
            intervalDropDown.value = []

            controldetailsDropdownData.INTERVALS.map((interval) => {
                intervalDropDown.value.push({
                    id: interval.ID,
                    value: tc(interval.VALUE, 0),
                })
            })
        }

        // convert id values to name values
        const convertIdToNames = () => {
            const executeType = utils.idToName(
                controldetailsDropdownData.EXECUTION_TYPE,
                props.entityDetails.executionType
            )
            executionTypeName.value = t(executeType, language.value)

            exPeriodUnitName.value = utils.idToName(
                controldetailsDropdownData.INTERVALS,
                props.entityDetails.executionIntervalUnit
            )

            exAssessmentUnitName.value = utils.idToName(
                controldetailsDropdownData.INTERVALS,
                props.entityDetails.assessmentIntervalUnit
            )
        }

        // clear form fields
        const clearFields = () => {
            formData.value = {
                PROCESS: '',
                EXECUTION_TYPE: null,
                EXECUTION_GUIDE: '',
                MSR_EXPLANATION: '',
                EXECUTION_INTERVAL_UNIT: 0,
                EXECUTION_INTERVAL_VALUE: 0,
                ASSESSMENT_INTERVAL_UNIT: 0,
                ASSESSMENT_INTERVAL_VALUE: 0,
                IMPLEMENTATION_GUIDE: '',
                ADDITIONAL_INFORMATION: '',
                CONSIDERATION: '',
                DOCUMENTATION_URL: '',
            }
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        // set form data
        const setFormData = () => {
            const msrDetails = props.entityDetails

            formData.value = {
                PROCESS: msrDetails.process,
                EXECUTION_TYPE: msrDetails.executionType,
                EXECUTION_GUIDE: msrDetails.executionGuide,
                MSR_EXPLANATION: msrDetails.msrExplanation,
                EXECUTION_INTERVAL_UNIT: msrDetails.executionIntervalUnit,
                EXECUTION_INTERVAL_VALUE: msrDetails.executionIntervalValue,
                ASSESSMENT_INTERVAL_UNIT: msrDetails.assessmentIntervalUnit,
                ASSESSMENT_INTERVAL_VALUE: msrDetails.assessmentIntervalValue,
                IMPLEMENTATION_GUIDE: msrDetails.implementationGuide,
                ADDITIONAL_INFORMATION: msrDetails.implementationGuideAdditionalInformation,
                CONSIDERATION: msrDetails.consideration,
                DOCUMENTATION_URL: msrDetails.documentationUrl,
            }

            convertIdToNames()
        }

        // reset fields on cancel
        const cancelForm = () => {
            setFormData()
            errors.value = {
                PROCESS: { missingValue: false },
                EXECUTION_INTERVAL_UNIT: { missingValue: false },
                EXECUTION_INTERVAL_VALUE: { missingValue: false },
                ASSESSMENT_INTERVAL_UNIT: { missingValue: false },
                ASSESSMENT_INTERVAL_VALUE: { missingValue: false },
            }
        }

        const validateAllFields = () => {
            errors.value.EXECUTION_INTERVAL_UNIT.missingValue = utils.validateFields(
                formData.value.EXECUTION_INTERVAL_UNIT,
                'number'
            )

            errors.value.EXECUTION_INTERVAL_VALUE.missingValue = utils.validateFields(
                formData.value.EXECUTION_INTERVAL_VALUE,
                'number'
            )

            errors.value.ASSESSMENT_INTERVAL_UNIT.missingValue = utils.validateFields(
                formData.value.EXECUTION_INTERVAL_UNIT,
                'number'
            )

            errors.value.ASSESSMENT_INTERVAL_VALUE.missingValue = utils.validateFields(
                formData.value.EXECUTION_INTERVAL_VALUE,
                'number'
            )
            // Process field is no longer mandatory
            // https://gitlab.com/stekz/perium/frontend/-/issues/870

            errors.value.PROCESS.missingValue = false
        }

        const validateForm = () => {
            // validate all fields when creating a new entity/ updating self created entity
            /* This super admin and employee seperate validations had to add as some madatory data of system entered records
                 are missing in db.In order to avoid throwing validation errors while editing such data, this condition has added.
                 We can remove this once we have stable data in db. (after BE ticket #458 is completed)*/
            if (!props.entityDetails || props.createdByUser) validateAllFields()
            else {
                // validate employee editable fields
                // missingValue checks
                errors.value.EXECUTION_INTERVAL_UNIT.missingValue = utils.validateFields(
                    formData.value.EXECUTION_INTERVAL_UNIT,
                    'number'
                )

                errors.value.EXECUTION_INTERVAL_VALUE.missingValue = utils.validateFields(
                    formData.value.EXECUTION_INTERVAL_VALUE,
                    'number'
                )

                errors.value.ASSESSMENT_INTERVAL_UNIT.missingValue = utils.validateFields(
                    formData.value.EXECUTION_INTERVAL_UNIT,
                    'number'
                )

                errors.value.ASSESSMENT_INTERVAL_VALUE.missingValue = utils.validateFields(
                    formData.value.EXECUTION_INTERVAL_VALUE,
                    'number'
                )
                // Process field is no longer mandatory
                // https://gitlab.com/stekz/perium/frontend/-/issues/870

                errors.value.PROCESS.missingValue = false
            }
        }

        // Fields which super admin can edit, will be view under following conditions
        const isSuperAdminViewFieldEnabled = computed(() => {
            return (
                !props.superAdminEdit &&
                !(props.createdByUser && props.editable) &&
                props.entityDetails
            )
        })

        // Fields which super admin can edit, will be editable under following conditions
        const isSuperAdminEditFieldEnabled = computed(() => {
            return (
                props.superAdminEdit ||
                (props.createdByUser && props.editable) ||
                !props.entityDetails
            )
        })

        // Fields which employee can edit, will be view under following conditions
        const isEmployeeViewFieldEnabled = computed(() => {
            return (
                !props.editable && props.entityDetails && !(props.createdByUser && props.editable)
            )
        })

        // Fields which employee can edit, will be editable under following conditions
        const isEmployeeEditFieldEnabled = computed(() => {
            return props.editable || !props.entityDetails || (props.createdByUser && props.editable)
        })

        /* display super admin editable string fields under following conditions 
        (#715, #795 - When fields have no data, they should not be shown in the details overview in View mode) */
        const enableSuperAdminStringFields = (field: string) => {
            return (
                (props.entityDetails &&
                    props.entityDetails[field] &&
                    props.entityDetails[field] !== '' &&
                    (!props.superAdminEdit || !props.editable)) ||
                props.superAdminEdit ||
                !props.entityDetails ||
                (props.createdByUser && props.editable)
            )
        }

        /* display super admin editable list fields under following conditions 
        (#715 - When fields have no data, they should not be shown in the details overview in View mode) */
        const enableSuperAdminListFields = (field: string) => {
            return (
                (props.entityDetails &&
                    props.entityDetails[field] &&
                    props.entityDetails[field].length > 0 &&
                    (!props.superAdminEdit || !props.editable)) ||
                props.superAdminEdit ||
                !props.entityDetails ||
                (props.createdByUser && props.editable)
            )
        }

        /* display employee editable string fields under following conditions 
        (#715, #795 - When fields have no data, they should not be shown in the details overview in View mode) */
        const enableEmployeeStringFields = (field: string) => {
            return (
                (props.entityDetails &&
                    props.entityDetails[field] &&
                    props.entityDetails[field] !== '' &&
                    (!props.superAdminEdit || !props.editable)) ||
                props.editable ||
                !props.entityDetails
            )
        }

        /* display employee editable dropdown fields where reading value is a number under following conditions 
        (#715, #795 - When fields have no data, they should not be shown in the details overview in View mode) */
        const enableEmployeeNumberValueFields = (field: string) => {
            return (
                (props.entityDetails &&
                    props.entityDetails[field] &&
                    props.entityDetails[field] !== 0 &&
                    (!props.superAdminEdit || !props.editable)) ||
                props.editable ||
                !props.entityDetails
            )
        }

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return props.editable || props.superAdminEdit || !props.entityDetails
        })

        onMounted(async () => {
            setExecutionTypeDropdownValues()
            setIntervalDropdownValues()
            if (props.entityDetails) {
                setFormData()
            }
        })

        // watch for language chaneges and update dropdowns
        watch(language, () => {
            setExecutionTypeDropdownValues()
            setIntervalDropdownValues()
            if (props.entityDetails) {
                convertIdToNames()
            }
        })

        return {
            t,
            language,
            moment,
            formData,
            executionTypeDropdow,
            intervalDropDown,
            executionTypeName,
            exPeriodUnitName,
            exAssessmentUnitName,
            textType,
            labelInfomationOpProcess,
            labelInfomationOpConsiderations,
            labelInfomationOpImplementationGuide,
            labelInfomationOpAdditionalInfomation,
            labelInfomationOpExecutionType,
            labelInfomationOpExecutionGuide,
            labelInfomationOpExecutionMSRPeriod,
            labelInfomationOpExecutionAssessment,
            labelInfomationOpNote,
            toggleInformation,
            cancelForm,
            utils,
            errors,
            validateForm,
            clearFields,
            labelInfomationOpDocumentationUrl,
            isSuperAdminViewFieldEnabled,
            isSuperAdminEditFieldEnabled,
            isEmployeeViewFieldEnabled,
            isEmployeeEditFieldEnabled,
            YesNo,
            msrDropdowns,
            enableEmployeeStringFields,
            enableSuperAdminStringFields,
            enableSuperAdminListFields,
            enableEmployeeNumberValueFields,
            isEditableMode,
        }
    },
})
