































































































































import utils from '@/shared/mixins/utils'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import Confirmation from '@/shared/components/Confirmation.vue'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import variables from '@/shared/variables'
import MSRTopSection from './MSRTopSection.vue'
import MSRBottomSection from './MSRBottomSection.vue'
import { UserRole } from '@/shared/enum/general-enum'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        enableEditSaveNext: {},
        categories: {
            type: Object,
        },
        isConnectedControl: {
            type: Boolean,
        },
    },
    components: {
        Confirmation,
        MSRTopSection,
        MSRBottomSection,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const superAdminEdit = ref(false)
        const createdByUser = ref(false)
        const editable = ref(false)
        const msrTopSectionCmp = ref(null)
        const msrBottomSectionCmp = ref(null)
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const { role } = useState(['role'])
        const saveType = ref('')
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const loading = ref(false)

        const enableEditButton = computed(() => {
            return (
                !superAdminEdit.value &&
                !editable.value &&
                (role.value === UserRole.EMPLOYEE ||
                    role.value === UserRole.PERIUM_ADMIN ||
                    role.value === UserRole.DEVELOPER_ADMIN) &&
                props.entityDetails &&
                !props.isConnectedControl
            )
        })

        // check whether the record is created by user or organisation
        const checkCreateByUserRecord = () => {
            const createdByUsername =
                props.entityDetails.created_by && props.entityDetails.created_by.username
            createdByUser.value = createdByUsername !== variables.DEFAULT_USERNAME ? true : false
        }

        const editButtonEnabled = computed(() => {
            return (
                !superAdminEdit.value &&
                !editable.value &&
                (role.value === UserRole.EMPLOYEE ||
                    role.value === UserRole.PERIUM_ADMIN ||
                    role.value === UserRole.DEVELOPER_ADMIN) &&
                props.entityDetails
            )
        })

        // go to record (previous/ next)
        const goToRecord = (type) => {
            emit('go-to-record', { no: props.entityDetails.no, type })
        }

        // enable edit fields
        const enableEdit = () => {
            if (role.value === UserRole.SUPER_ADMIN) {
                superAdminEdit.value = true
            } else {
                editable.value = true
            }
        }
        // cancel edit
        const cancelEdit = () => {
            editable.value = false
            superAdminEdit.value = false
            if (props.entityDetails) {
                msrTopSectionCmp.value.cancelForm()
                msrBottomSectionCmp.value.cancelForm()
            } else {
                emit('close-create-new')
            }
        }

        // save msr data
        const save = (typeOfSave: string) => {
            msrBottomSectionCmp.value.validateForm()
            msrTopSectionCmp.value.validateForm()

            if (
                utils.hasErrors(msrBottomSectionCmp.value.errors) ||
                utils.hasErrors(msrTopSectionCmp.value.errors)
            ) {
                return
            }
            const formData = Object.assign(
                msrTopSectionCmp.value.formData,
                msrBottomSectionCmp.value.formData
            )
            if (props.entityDetails) {
                emit('save-msr', {
                    id: props.entityDetails.id,
                    formData: formData,
                    createdBy: createdByUser.value,
                    typeOfSave,
                    no: props.entityDetails.no,
                })
            } else {
                emit('save-msr', {
                    formData: formData,
                })
            }
        }

        // open save confirmation
        const openSaveConfirmation = (type) => {
            saveType.value = type
            if (props.entityDetails) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                save(type)
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            if (props.entityDetails) {
                displayCancelConfirmation.value = true
            } else {
                // for new create entity
                cancelEdit()
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            save(saveType.value)
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancelEdit()
        }

        onMounted(async () => {
            if (props.entityDetails) {
                checkCreateByUserRecord()
            }
        })

        return {
            t,
            language,
            utils,
            goToRecord,
            editable,
            enableEdit,
            superAdminEdit,
            createdByUser,
            cancelEdit,
            save,
            role,
            variables,
            msrTopSectionCmp,
            msrBottomSectionCmp,
            successCancelConfirmation,
            successSaveConfirmation,
            openCancelConfirmation,
            openSaveConfirmation,
            displayCancelConfirmation,
            displaySaveConfirmation,
            saveType,
            UserRole,
            loading,
            editButtonEnabled,
            enableEditButton,
        }
    },
})
