










































































import { useState } from '@/shared/mixins/helpers'
import { defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import Table from '@/shared/components/Table.vue'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import generalData from '@/assets/data/general-data.json'
import moment from 'moment'
import controlAssessmentDropdownData from '@/assets/data/control-assessment-data.json'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import MSRAssessment from '@/components/msr-assessments/MSRAssessment.vue'
import msrQueries from '@/shared/queries/msrQueries'
import { MSRAssessmentDTO } from '@/dto/backend-response/msrAssessmentsDTO'
import ConfirmMessage from '@/shared/components/ConfirmMessage.vue'

export default defineComponent({
    props: {
        msrDetails: {
            type: Object,
        },
    },
    components: {
        Table,
        MSRAssessment,
        ConfirmMessage,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const confirm = ref(null)
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const msrAssessments = ref([])
        const loading = ref(false)
        const displayMsrAssessment = ref(false)
        const lastRecordNo = ref(null)
        const firstRecordNo = ref(null)
        const msrAssessmentRecordNo = ref(null)
        const tableCmp = ref(null)
        const { role } = useState(['role'])
        const assessmentId = ref(null)
        const newAssessmentSourceId: Ref<number> = ref(null)

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                },
                {
                    header: t('ASSESSMENT_LABEL_EXECUTION_DATE', language.value),
                    fieldName: 'fexecutionDate',
                },
                {
                    header: t('MSR_ASSESSMENTS_TABLE_COLUMN_EXECUTED_BY', language.value),
                    fieldName: 'fexecutedBy',
                },
                {
                    header: t('MSR_ASSESSMENTS_TABLE_COLUMN_MATURITY', language.value),
                    fieldName: 'maturity',
                },
                {
                    header: t('MSR_ASSESSMENTS_TABLE_COLUMN_DESIGN', language.value),
                    fieldName: 'design',
                    effectiveness: true,
                },
                {
                    header: t('MSR_ASSESSMENTS_TABLE_COLUMN_EXISTENCE', language.value),
                    fieldName: 'existence',
                    effectiveness: true,
                },
                {
                    header: t(
                        'MSR_ASSESSMENTS_TABLE_COLUMN_OPERATIONAL_EFFECTIVENESS',
                        language.value
                    ),
                    fieldName: 'operationalEffectiveness',
                    effectiveness: true,
                },
                {
                    header: t('MSR_ASSESSMENTS_TABLE_COLUMN_STATUS', language.value),
                    fieldName: 'status',
                },
                {
                    header: t('MSR_ASSESSMENTS_TABLE_COLUMN_PERIOD', language.value),
                    fieldName: 'period',
                },
            ]
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, state)

            return t(stateVal, language.value)
        }

        // go to msr-assessment record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `msrAssessments`  or sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // find the record id (of previous/ next)
                assessmentId.value = msrAssessments.value.find(
                    (assessment) => assessment.no === record.no
                ).id
                msrAssessmentRecordNo.value = record.no
            }
        }

        // updating msrAssessments array with additional fields and setting description content
        const toTableFormat = (mas: Array<MSRAssessmentDTO>) => {
            msrAssessments.value = []
            mas.map((msrAssessment) => {
                let ma = {
                    no: null,
                    fexecutedBy: '',
                    fexecutionDate: '',
                    status: null,
                    fmaturity: '',
                    fdesign: '',
                    fexistence: '',
                    foperationalEffectiveness: '',
                    period: '',
                }
                // creating a copy of object to hold formatted and extra attributes
                ma = Object.create(msrAssessment)
                ma.no = utils.padLeft('000', msrAssessment.refId)
                ma.fexecutedBy =
                    msrAssessment.executedBy &&
                    msrAssessment.executedBy.firstName + ' ' + msrAssessment.executedBy.lastName
                ma.fexecutionDate = msrAssessment.executionDate
                    ? moment(msrAssessment.executionDate).format('DD-MM-YYYY')
                    : ''
                ma.status = convertIdToNames(msrAssessment.state)
                // using same control assessment maturity dropdown values for msr assessment maturity field
                ma.fmaturity = controlAssessmentDropdownData.MATURITY.find(
                    (val) => val.SCORE === msrAssessment.maturity
                )?.VALUE
                if (msrAssessment.opStartDate && msrAssessment.opEndDate) {
                    ma.period =
                        (msrAssessment.opStartDate &&
                            moment(msrAssessment.opStartDate).format('DD-MM-YYYY')) +
                        '-' +
                        (msrAssessment.opEndDate &&
                            moment(msrAssessment.opEndDate).format('DD-MM-YYYY'))
                }
                msrAssessments.value.push(ma)
            })
        }

        // get msr assessments
        const getMSRAssessments = async () => {
            loading.value = true
            const query = `
               query{
                    msrAssessments(msrId:${props.msrDetails.id}){
                        status
                        error
                        items{
                            ${msrQueries.MSR_ASSESSMENTS_LIST_TAB}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving msr assessments')
            }
            if (result) {
                const assessments = result.data.msrAssessments.items
                // sort by executionDate (newest to old)
                assessments.sort((a, b) => (a.executionDate < b.executionDate ? 1 : -1))
                // updating msrAssessments with additional fields and setting description content
                toTableFormat(assessments)
            }
        }

        // msr assessment details sidebar
        const openMSRAssessmentPopup = (id) => {
            displayMsrAssessment.value = true
            assessmentId.value = id
            msrAssessmentRecordNo.value = msrAssessments.value.find(
                (assessment) => assessment.id == id
            ).no

            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        const createNewAssessment = () => {
            assessmentId.value = null
            const latestAssessmentId = msrAssessments.value?.[0]?.id

            const createEmptyAssessment = () => {
                newAssessmentSourceId.value = null
                displayMsrAssessment.value = true
            }

            const createAssessmentFromLatest = () => {
                newAssessmentSourceId.value = latestAssessmentId
                displayMsrAssessment.value = true
            }

            if (latestAssessmentId) {
                // Ask user if we should use the latest assessment or not
                confirm.value.confirm(
                    "CONFIRMATION_POPUP_USE_PREVIOUS_ASSESSMENT_RESULTS",
                    createAssessmentFromLatest,
                    createEmptyAssessment,
                )
            } else {
                // There are no assessments to reuse, so create one from scratch
                createEmptyAssessment()
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                const thirdSidebar = document.querySelector('.third-sidebar')
                if (event.key === 'Escape' && !thirdSidebar) {
                    //close assessment sidebar if it's open and actionplan sidebar(third-sidebar) is closed
                    if (displayMsrAssessment.value) {
                        displayMsrAssessment.value = false
                    }
                }
            })
        }

        const updateRecords = () => {
            getMSRAssessments()
            // refresh the details page
            emit('post-assessment-update', { no: props.msrDetails.no, type: '' })
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            () => {
                setTableHeaders()
            },
            {
                immediate: true,
            }
        )

        onMounted(() => {
            closeSidebarEsc()
            getMSRAssessments()
        })

        return {
            tableHeaders,
            msrAssessments,
            openMSRAssessmentPopup,
            loading,
            displayMsrAssessment,
            getMSRAssessments,
            assessmentId,
            lastRecordNo,
            msrAssessmentRecordNo,
            tableCmp,
            goToRecord,
            variables,
            role,
            t,
            language,
            confirm,
            updateRecords,
            UserRole,
            firstRecordNo,
            newAssessmentSourceId,
            createNewAssessment,
        }
    },
})
