var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-mt-3"},[_c('loading',{attrs:{"active":_vm.loading,"can-cancel":true,"color":"#05BE36","is-full-page":false}}),(_vm.entityDetails)?_c('div',[_c('MSRTopSection',{ref:"msrTopSectionCmp",attrs:{"entityDetails":_vm.entityDetails,"editable":_vm.editable,"superAdminEdit":_vm.superAdminEdit,"createdByUser":_vm.createdByUser,"controlCategories":_vm.categories}}),_c('MSRBottomSection',{ref:"msrBottomSectionCmp",attrs:{"entityDetails":_vm.entityDetails,"editable":_vm.editable,"superAdminEdit":_vm.superAdminEdit,"createdByUser":_vm.createdByUser}}),_c('div',{staticClass:"button-section"},[_c('div',{staticClass:"p-grid action-btn-group"},[_c('div',{staticClass:"p-col left-side-buttons"},[(_vm.enableEditButton)?_c('div',{staticClass:"details-btn"},[_c('Button',{staticClass:"p-button-outlined",attrs:{"label":_vm.t('EDIT_DETAILS', _vm.language)},on:{"click":function($event){return _vm.enableEdit()}}})],1):_vm._e(),(
                            !_vm.superAdminEdit &&
                            !_vm.editable &&
                            _vm.role === _vm.UserRole.SUPER_ADMIN &&
                            !_vm.createdByUser &&
                            _vm.entityDetails
                        )?_c('div',{staticClass:"details-btn"},[_c('Button',{staticClass:"p-button-outlined admin-edit-button",attrs:{"icon":"fas fa-cog"},on:{"click":function($event){return _vm.enableEdit()}}})],1):_vm._e()]),_c('div',{staticClass:"p-col right-side-buttons"},[(!_vm.editable && !_vm.superAdminEdit && _vm.entityDetails)?_c('span',{staticClass:"previous-btn"},[_c('Button',{staticClass:"p-button-outlined",attrs:{"disabled":_vm.entityDetails.no === _vm.entityDetails.firstRecordNo,"label":_vm.t('PREVIOUS', _vm.language)},on:{"click":function($event){return _vm.goToRecord('previous')}}})],1):_vm._e(),(!_vm.editable && !_vm.superAdminEdit && _vm.entityDetails)?_c('span',{staticClass:"next-btn p-ml-3"},[_c('Button',{staticClass:"p-button-outlined",attrs:{"label":_vm.t('NEXT', _vm.language),"disabled":_vm.entityDetails.no === _vm.entityDetails.lastRecordNo},on:{"click":function($event){return _vm.goToRecord('next')}}})],1):_vm._e(),(_vm.editable || _vm.superAdminEdit || !_vm.entityDetails)?_c('span',[_c('Button',{staticClass:"p-button-outlined",attrs:{"label":_vm.t('CANCEL', _vm.language)},on:{"click":function($event){return _vm.openCancelConfirmation()}}})],1):_vm._e(),(_vm.editable || _vm.superAdminEdit)?_c('span',{staticClass:"p-ml-3"},[_c('Button',{staticClass:"p-button-outlined",attrs:{"label":_vm.t('GENERAL_TABLE_BTN_EXPAND_SAVE_AND_NEXT', _vm.language),"disabled":_vm.entityDetails.no === _vm.entityDetails.lastRecordNo},on:{"click":function($event){_vm.saveType = 'save-next'
                                _vm.displaySaveConfirmation = true}}})],1):_vm._e(),(_vm.editable || _vm.superAdminEdit || !_vm.entityDetails)?_c('span',{staticClass:"p-ml-3"},[_c('Button',{attrs:{"label":_vm.t('GENERAL_TABLE_BTN_EXPAND_SAVE', _vm.language)},on:{"click":_vm.openSaveConfirmation}})],1):_vm._e()])])])],1):_vm._e(),_c('Confirmation',{attrs:{"displaySaveConfirmation":_vm.displaySaveConfirmation,"message":_vm.t('CONFIRMATION_POPUP_SAVE_CONFIRMATION_MESSAGE', _vm.language)},on:{"close-confirmation":function($event){_vm.displaySaveConfirmation = false},"success-confirm":_vm.successSaveConfirmation}}),_c('Confirmation',{attrs:{"displaySaveConfirmation":_vm.displayCancelConfirmation,"message":_vm.t('CONFIRMATION_POPUP_CANCEL_CONFIRMATION_MESSAGE', _vm.language)},on:{"close-confirmation":function($event){_vm.displayCancelConfirmation = false},"success-confirm":_vm.successCancelConfirmation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }